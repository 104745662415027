@use 'src/assets/scss/mixins/transitions';

%cursor-pointer-transition-color {
  text-decoration: none;
  cursor: pointer;
  @include transitions.transition-color;
}

.qaroni-link {
  @extend %cursor-pointer-transition-color;
  color: var(--qaroni-primary) !important;

  &:hover {
    color: var(--qaroni-primary50) !important;
    text-decoration: none;
  }
}

.qaroni-link-on-surface {
  @extend %cursor-pointer-transition-color;
  color: var(--qaroni-primary60) !important;

  &:hover {
    color: var(--qaroni-primary30) !important;
    text-decoration: none;
  }
}

.qaroni-dark-link {
  @extend %cursor-pointer-transition-color;
  color: var(--qaroni-primary40) !important;
  font-weight: bold;

  &:hover {
    color: var(--qaroni-primary60) !important;
    text-decoration: none;
  }
}

.qaroni-error-link {
  @extend %cursor-pointer-transition-color;
  color: var(--qaroni-error) !important;

  &:hover {
    color: var(--qaroni-error30) !important;
    text-decoration: none;
  }
}

.qaroni-router-link-active {
  border-right: 6px solid var(--qaroni-background) !important;
  border-radius: 5px !important;
}

.qaroni-text-neutral-to-danger-light {
  color: var(--qaroni-neutral90);
  @include transitions.transition-color;

  &:hover {
    color: var(--qaroni-danger-light);
  }
}

.qaroni-text-neutral-to-primary {
  color: var(--qaroni-neutral90);
  @include transitions.transition-color;

  &:hover {
    color: var(--qaroni-primary60);
  }
}
