@use 'src/assets/scss/mixins/transitions';

table.mat-mdc-table {
  &.full-width-table {
    width: 100%;
  }

  &.qaroni-table-change-bg-row {
    .mat-mdc-row {
      @include transitions.transition-background-color(350ms);

      &:hover {
        background-color: var(--qaroni-neutral95);
      }
    }
  }

  th.mat-mdc-header-cell {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
  }

  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    &:not(:first-child) {
      padding-left: 0.75rem;
    }

    &:not(:last-child) {
      padding-right: 0.75rem;
    }
  }

  .mat-mdc-row .mat-mdc-cell {
    cursor: pointer;
  }
}

.qaroni-table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.qaroni-table-responsive > .table {
  margin-bottom: 0;
}
