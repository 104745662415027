.mat-calendar
  .mat-calendar-content
  .mat-calendar-table
  .mat-calendar-body
  .mat-calendar-body-cell-container
  .mat-calendar-body-cell {
  &.qaroni-holiday-date {
    background-color: var(--qaroni-primary40) !important;
    border-radius: 50%;

    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral100) !important;
    }
  }

  &.qaroni-national-holiday-date {
    background-color: var(--qaroni-accent) !important;
    border-radius: 50%;

    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral100) !important;
    }
  }

  &.qaroni-regional-holiday-date {
    background-color: var(--qaroni-secondary) !important;
    border-radius: 50%;

    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral100) !important;
    }
  }

  &.qaroni-local-holiday-date {
    background-color: var(--qaroni-tertiary) !important;
    border-radius: 50%;

    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral100) !important;
    }
  }

  &.qaroni-internal-holiday-date {
    background-color: var(--qaroni-primary40) !important;
    border-radius: 50%;

    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral100) !important;
    }
  }

  &.qaroni-schedule-start-date {
    background-color: #11aff67d;
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;

    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral60);
    }
  }

  &.qaroni-schedule-end-date {
    background-color: #11aff67d;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;

    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral60);
    }
  }

  &.qaroni-schedule-middle-date {
    background-color: #11aff645;

    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral60);
    }
  }

  &.qaroni-schedule-has-data {
    &::after {
      content: '';
      background-color: var(--qaroni-stripe);
      width: 4px;
      height: 4px;
      left: 3px;
      right: 0;
      margin: 0 auto;
      z-index: 1;
      border-radius: 2px;
      top: unset !important;
      bottom: 6px;
    }
  }

  &.qaroni-schedule-national-holiday-date {
    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral100) !important;
      background-color: var(--qaroni-accent) !important;
      border-radius: 50%;
      top: 0 !important;
      height: 100%;
      width: 100%;
    }
  }

  &.qaroni-schedule-regional-holiday-date {
    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral100) !important;
      background-color: var(--qaroni-secondary) !important;
      border-radius: 50%;
      top: 0 !important;
      height: 100%;
      width: 100%;
    }
  }

  &.qaroni-schedule-local-holiday-date {
    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral100) !important;
      background-color: var(--qaroni-tertiary) !important;
      border-radius: 50%;
      top: 0 !important;
      height: 100%;
      width: 100%;
    }
  }

  &.qaroni-schedule-internal-holiday-date {
    .mat-calendar-body-cell-content {
      color: var(--qaroni-neutral100) !important;
      background-color: var(--qaroni-primary40) !important;
      border-radius: 50%;
      top: 0 !important;
      height: 100%;
      width: 100%;
    }
  }

  &.qaroni-schedule-absence-date {
    &::before {
      content: '';
      background-color: var(--qaroni-warn25);
      width: 4px;
      height: 4px;
      left: 3px;
      right: 0;
      margin: 0 auto;
      z-index: 3;
      border-radius: 2px;
      top: 6px !important;
    }
  }
}

.cdk-visually-hidden {
  display: none;
}

.container-schedule-calendar {
  .mat-calendar {
    .mat-calendar-content {
      table {
        border-collapse: separate !important;
        border-spacing: 0 5px !important;
      }
    }
  }
}
