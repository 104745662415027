.priority-low-color {
  background-color: var(--qaroni-success);
}
.priority-middle-color {
  background-color: var(--qaroni-tertiary);
}
.priority-high-color {
  background-color: var(--qaroni-accent);
}
.priority-urgent-color {
  background-color: var(--qaroni-error50);
}
