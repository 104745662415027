/* Importing Theming Angular Material file. */
@use 'assets/scss/material-theme';

@use 'assets/scss/accordion';
@use 'assets/scss/badge';
@use 'assets/scss/borders';
@use 'assets/scss/buttons';
@use 'assets/scss/calendars';
@use 'assets/scss/cards';
@use 'assets/scss/circle-color';
@use 'assets/scss/colors-factory';
@use 'assets/scss/colors-list';
@use 'assets/scss/dialogs';
@use 'assets/scss/divider';
@use 'assets/scss/drag-drop';
@use 'assets/scss/fonts-styles';
@use 'assets/scss/fonts';
@use 'assets/scss/icons';
@use 'assets/scss/inputs';
@use 'assets/scss/links';
@use 'assets/scss/lists';
@use 'assets/scss/scrollbar';
@use 'assets/scss/slide-toggle';
@use 'assets/scss/spacing';
@use 'assets/scss/spinner';
@use 'assets/scss/tables';
@use 'assets/scss/tabs';
@use 'assets/scss/toolbars';
@use 'assets/scss/tooltips';
@use 'assets/scss/chips';
@use 'assets/scss/progress-bar';
@use 'assets/scss/containers';

/* Importing overlay styles. */
@import '@angular/cdk/overlay-prebuilt.css';

/*importing material symbols outlined*/
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined');

/* Importing Tailwindcss styles*/
@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  height: 100vh;
  max-height: 100vh;
  margin: 0;
  font-family: 'Proxima Nova', sans-serif;
}
