@use 'src/assets/scss/mixins/breakpoints' as bk;

.qaroni-container-md {
  width: 100%;
  height: 100vh !important;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;

  @include bk.sm {
    width: 100%;
  }

  @include bk.md {
    width: 100%;
  }

  @include bk.lg {
    width: 90%;
  }

  @include bk.xl {
    width: 85%;
  }

  @include bk.xxl {
    width: 75%;
  }
}
