.mat-mdc-nav-list.sidenav-menu {
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-focus-state-layer-color: white;

  padding-top: 0px !important;

  .mat-mdc-list-item {
    background-color: transparent;
    height: 36px !important;
    font-size: 16px;
    font-weight: 400;

    &:hover {
      background-color: transparent;
      border-right: 6px solid var(--qaroni-background);
      border-radius: 5px;
    }

    &:not(:last-child) {
      margin-bottom: 3px;
    }
  }

  .mat-mdc-list-item-disabled {
    cursor: not-allowed !important;
    opacity: 0.5;
    text-decoration: none;
  }
}

.qaroni-mat-list-small {
  .mat-mdc-list-option .mat-line:nth-child(n + 2) {
    font-size: 10px !important;
  }

  .mat-mdc-list-item.mat-2-line,
  .mat-mdc-list-option.mat-2-line {
    height: 56px !important;
  }
}
