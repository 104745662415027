.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  display: flex;
  align-items: center;
}

.imputation-chip.mat-mdc-standard-chip {
  margin-left: 0px !important;
  .mat-mdc-chip-action-label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.imputation-chip-grid.mdc-evolution-chip-set .mdc-evolution-chip-set__chips {
  margin-left: 0px;
}

.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
.mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-label-text-color: white;
}

.text-xs.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  font-size: 12px;
}

.text-sm.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  font-size: 14px;
}

.qaroni-chip-status {
  color: white;
  font-size: 1rem;
  font-weight: 500;
  padding: 4px 12px;
  border-radius: 999px;
  line-height: 1.5rem;
  cursor: default;

  &:hover {
    filter: brightness(0.95);
  }
}
