%qaroni-card-bg-background-base {
  background-color: var(--qaroni-background);
  border: 1px solid var(--qaroni-surface-border);
}

.mat-mdc-card.qaroni-card-bg-background {
  @extend %qaroni-card-bg-background-base;
}

.qaroni-mat-card-message {
  @extend %qaroni-card-bg-background-base;
  text-align: center;
  margin: 0 1rem;
  max-width: 600px;
  border-radius: 10px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .mat-mdc-card-title {
    padding-top: 44px;
    padding-bottom: 20px;
    color: var(--qaroni-on-background);
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  .mat-mdc-card-content {
    text-align: center;
    font-size: 16px;
    padding-bottom: 30px;
    margin-bottom: 0;
  }

  .mat-mdc-card-actions {
    padding-top: 0;
    a {
      margin-bottom: 42px;
    }
  }
}

.mat-mdc-card {
  box-shadow: none !important;
}

.mat-mdc-card.qaroni-bg-surface.qaroni-surface-border.card-stats-widget {
  border-radius: 5px;
  min-height: 250px;
  display: grid;
  grid-template-rows: max-content max-content 1fr;
  align-items: center;
  padding: 0 1rem;

  .mat-card-header {
    display: flex;
    align-items: center;
    min-height: 40px;

    .mat-mdc-card-title {
      font-size: 16px;
      display: inline;
      margin-right: 0.3rem;
      color: var(--qaroni-primary60);
      font-weight: normal;
    }
  }

  .mat-card-content {
    height: 100%;
    padding: 12px 0;
  }
}
