.mat-tooltip-panel {
  .mat-tooltip.qaroni-info-tooltip {
    white-space: pre-line;
  }
}

.mdc-tooltip {
  border-radius: 4px;
  background-color: gray;
  color: white;
  font-size: 12px;
}
