@font-face {
  font-family: 'Sofia Pro';
  src:
    local('Sofia Pro'),
    url('../fonts/sofia-pro/thin/sofia-pro-thin.eot')
      format('embedded-opentype'),
    url('../fonts/sofia-pro/thin/sofia-pro-thin.woff2') format('woff2'),
    url('../fonts/sofia-pro/thin/sofia-pro-thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Sofia Pro';
  src:
    local('Sofia Pro'),
    url('../fonts/sofia-pro/bold/sofia-pro-bold.eot')
      format('embedded-opentype'),
    url('../fonts/sofia-pro/bold/sofia-pro-bold.woff2') format('woff2'),
    url('../fonts/sofia-pro/bold/sofia-pro-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    local('Proxima Nova'),
    url('../fonts/proxima-nova/thin/proxima-nova-thin.opentype')
      format('opentype'),
    url('../fonts/proxima-nova/thin/proxima-nova-thin.woff2') format('woff2'),
    url('../fonts/proxima-nova/thin/proxima-nova-thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    local('Proxima Nova'),
    url('../fonts/proxima-nova/light/proxima-nova-light.opentype')
      format('opentype'),
    url('../fonts/proxima-nova/light/proxima-nova-light.woff2') format('woff2'),
    url('../fonts/proxima-nova/light/proxima-nova-light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    local('Proxima Nova'),
    url('../fonts/proxima-nova/regular/proxima-nova-regular.opentype')
      format('opentype'),
    url('../fonts/proxima-nova/regular/proxima-nova-regular.woff2')
      format('woff2'),
    url('../fonts/proxima-nova/regular/proxima-nova-regular.woff')
      format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    local('Proxima Nova'),
    url('../fonts/proxima-nova/medium/proxima-nova-medium.opentype')
      format('opentype'),
    url('../fonts/proxima-nova/medium/proxima-nova-medium.woff2')
      format('woff2'),
    url('../fonts/proxima-nova/medium/proxima-nova-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src:
    local('Proxima Nova'),
    url('../fonts/proxima-nova/bold/proxima-nova-bold.opentype')
      format('opentype'),
    url('../fonts/proxima-nova/bold/proxima-nova-bold.woff2') format('woff2'),
    url('../fonts/proxima-nova/bold/proxima-nova-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
