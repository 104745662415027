$spacer: 1rem;

$sizes: (
  6: $spacer * 5,
  7: $spacer * 10,
  8: $spacer * 15,
  9: $spacer * 20,
  10: $spacer * 30,
);

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }
    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }
    .#{$abbrev}e-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }
    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }
    .#{$abbrev}s-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}
