@use 'sass:math';

.mat-mdc-form-field {
  --mdc-outlined-text-field-outline-color: var(--qaroni-surface-border);
  width: 100%;
}

.mat-mdc-form-field.qaroni-ff-no-bottom-space {
  .mat-mdc-text-field-wrapper {
    margin-bottom: -1.25em;
  }
}

.mat-mdc-form-field.qaroni-ff-no-space {
  .mat-mdc-form-field-infix {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    min-height: auto;
  }
}

@supports (-moz-appearance: none) {
  .mat-mdc-form-field.qaroni-moz-suffix {
    .mat-mdc-form-field-suffix {
      top: 0.6em;
    }
  }
}

.qaroni-input-box {
  width: 378px;
  padding: 0 1rem;
  max-width: 100%;
}

// Styles that only apply to the outline appearance of the form-field.

// The horizontal padding between the edge of the form field fill box and the start of the text.
$mat-form-field-outline-side-padding: 1.75em;
// The scale of the subscript and floating label text w.r.t the value text.
$mat-form-field-outline-subscript-font-scale: 0.75;
// The amount of overlap between the label and the outline.
$mat-form-field-outline-label-overlap: 0.45em;
// The border radius of the outline.
//$mat-form-field-outline-border-radius: 26px;
// The border radius of the outline.
$mat-form-field-outline-border-radius: 28px;
// The width of the outline.
$mat-form-field-outline-width: 1px;
// The width of the thick outline (used for focus, hover, and error state).
$mat-form-field-outline-thick-width: 2px;
// The horizontal padding between the edge of the subscript box and the start of the subscript text.
$mat-form-field-outline-subscript-padding: math.div(
  $mat-form-field-outline-side-padding,
  $mat-form-field-outline-subscript-font-scale
);

$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);

.mat-mdc-form-field.mat-form-field-appearance-outline.outline-rounded.bg-light-blue {
  .mat-mdc-text-field-wrapper {
    border-radius: $mat-form-field-outline-border-radius !important;
    background-color: var(--qaroni-neutral98);
  }
}

.mat-mdc-form-field.outline-rounded {
  .mdc-text-field--outlined {
    //form-field global variables cannot be values ​​of local variables
    --mdc-outlined-text-field-outline-width: 2px;
    --mdc-outlined-text-field-focus-outline-width: 2px;
    --mdc-outlined-text-field-container-shape: 28px !important;
  }
}

.mdc-notched-outline__notch {
  border-right: none;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

.mat-mdc-form-field.icon-select {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding-bottom: 0px !important;
      }
    }
  }
}
