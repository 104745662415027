@use 'src/assets/scss/mixins/buttons';

@include buttons.qaroni-build-mat-color(
  'mat-blue',
  var(--qaroni-primary30),
  var(--qaroni-primary95)
);

@include buttons.qaroni-build-mat-color(
  'mat-light-blue',
  var(--qaroni-primary100),
  var(--qaroni-primary30)
);

@include buttons.qaroni-build-mat-color(
  'mat-primary60',
  var(--qaroni-primary60),
  var(--qaroni-primary95)
);

@include buttons.qaroni-build-mat-color(
  'mat-light-white',
  var(--qaroni-background),
  var(--qaroni-on-background)
);

@include buttons.qaroni-build-mat-color(
  'mat-on-surface',
  var(--qaroni-on-surface),
  var(--qaroni-surface)
);

@include buttons.qaroni-build-mat-color(
  'mat-danger',
  var(--qaroni-danger),
  var(--qaroni-on-danger)
);

@include buttons.qaroni-build-mat-color(
  'mat-danger-light',
  var(--qaroni-danger-light),
  var(--qaroni-on-danger)
);

@include buttons.qaroni-build-mat-color(
  'mat-stripe',
  var(--qaroni-stripe),
  var(--qaroni-background)
);

%qaroni-rounded-button-base {
  font-weight: 700;
  font-weight: 700;
  max-width: 100%;
  height: 45px;
  border-radius: 28px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.mat-mdc-unelevated-button.qaroni-auth-google {
  @extend %qaroni-rounded-button-base;
  width: 272px;
  height: 45px;
  font-size: 14px;
  text-transform: none;
  font-weight: 600;
  color: var(--qaroni-neutral40);

  div.qaroni-auth-google-text {
    display: flex;
    font-family: 'Open Sans', sans-serif;
    align-items: center;
    line-height: 45px;
    justify-content: center;
    img {
      width: 32px;
      margin: 7px 10px 6px 0px;
    }
  }
}

.mat-mdc-unelevated-button {
  &.qaroni-rounded-lg-button,
  &.qaroni-rounded-button,
  &.qaroni-rounded-sm-button {
    @extend %qaroni-rounded-button-base;
  }
}

.mat-mdc-unelevated-button.qaroni-rounded-lg-button {
  min-width: 269px;
  font-size: 16px;
  height: 45px;
}

.mat-mdc-unelevated-button.qaroni-rounded-button {
  min-width: 120px;
  font-size: 14px;
  height: 36px;
}

.mat-mdc-unelevated-button.qaroni-rounded-sm-button {
  min-width: 120px;
  font-size: 12px;
  height: 20px;
  line-height: 14px;
  text-transform: uppercase;
}

.mat-mdc-icon-button,
.mat-mdc-mini-fab {
  .mat-button-wrapper > .mat-icon {
    margin-top: -4px;
  }
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-fab,
.mat-mdc-mini-fab {
  outline: none !important;
  font-weight: 700 !important;
}

.mat-mdc-fab,
.mat-mdc-mini-fab,
.mat-mdc-icon-button {
  box-shadow: none !important;
}

%qaroni-mat-mini-fab-custom-base {
  width: 32px;
  height: 32px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.qaroni-mat-mini-fab-custom {
  &.qaroni-mat-mini-fab-custom {
    @extend %qaroni-mat-mini-fab-custom-base;
  }
}

%qaroni-mat-mini-fab-size-base {
  height: 24px;
  width: 24px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.qaroni-mat-mini-fab-size {
  &.qaroni-mat-mini-fab-size {
    @extend %qaroni-mat-mini-fab-size-base;
  }
}

.mdc-button {
  --mdc-text-button-label-text-tracking: 0px !important;
  .mdc-button__label {
    display: flex;
    align-items: center;
  }
}
