// Small devices
@mixin sm {
  @media (min-width: 576px) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: 768px) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: 992px) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: 1200px) {
    @content;
  }
}

// 2Extra large devices
@mixin xxl {
  @media (min-width: 1440px) {
    @content;
  }
}

// 3Extra large devices
@mixin xxxl {
  @media (min-width: 1680px) {
    @content;
  }
}

// Custom devices
@mixin rwbp($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}
