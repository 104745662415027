%mat-spinner-customer-base {
  height: 35px !important;
  width: 35px !important;
}

.mat-mdc-progress-spinner {
  &.mat-spinner-customer {
    @extend %mat-spinner-customer-base;

    svg {
      height: 35px !important;
      width: 35px !important;
    }
  }
}
