.qaroni-sections-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.qaroni-section-content {
  min-height: 212px;
}

.qaroni-section-task {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: white;
  margin-bottom: 0.5rem;

  .qaroni-section-task-actions {
    min-height: 40px !important;
    min-width: 40px !important;
  }
}

.qaroni-section-task:last-child {
  margin-bottom: 0;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.qaroni-section-content.cdk-drop-list-dragging
  .qaroni-section-task:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
