.container-circle {
  width: 16px;
  height: 16px;
}

.content-circle {
  width: 8px;
  height: 8px;
}

.container-circle-success {
  background: var(--qaroni-secondary90);
}

.circle-success {
  background: var(--qaroni-secondary);
}

.container-circle-danger {
  background: var(--qaroni-error90);
}

.circle-danger {
  background: var(--qaroni-error50);
}

.container-circle-warning {
  background: var(--qaroni-accent90);
}

.circle-warning {
  background: var(--qaroni-accent);
}

.container-circle-info {
  background: var(--qaroni-primary90);
}

.circle-info {
  background: var(--qaroni-primary40);
}

.container-circle-secondary {
  background: var(--qaroni-neutral70);
}

.circle-white {
  background: var(--qaroni-neutral100);
}

.icon-profile {
  max-height: 40px;
  min-height: 40px;
  width: auto;
  margin: auto;
  border-radius: 50%;
}
