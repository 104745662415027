.mat-accordion {
  .panel-title {
    font-size: 16px;
  }

  .panel-title,
  .mat-icon {
    color: var(--qaroni-background) !important;
  }

  .mat-expansion-panel {
    background-color: transparent;
    border-radius: 25px !important;

    .mat-expansion-panel-header {
      padding: 0 16px !important;
    }
  }
}

.mat-accordion:hover {
  .panel-title,
  .mat-icon {
    color: var(--qaroni-primary) !important;
  }

  .mat-expansion-panel {
    background-color: var(--qaroni-background);
  }
}

.mat-accordion-open {
  .panel-title,
  .mat-icon,
  a {
    color: var(--qaroni-primary) !important;
  }
}
