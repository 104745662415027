:root {
  --qaroni-primary: #82c4f8;
  --qaroni-primary0: #000000;
  --qaroni-primary10: #001e30;
  --qaroni-primary20: #00344f;
  --qaroni-primary25: #003f60;
  --qaroni-primary30: #004b71;
  --qaroni-primary35: #005783;
  --qaroni-primary40: #006495;
  --qaroni-primary50: #0e7eb8;
  --qaroni-primary60: #3c98d4;
  --qaroni-primary70: #5cb3f0;
  --qaroni-primary80: #8fcdff;
  --qaroni-primary90: #cbe6ff;
  --qaroni-primary95: #e6f2ff;
  --qaroni-primary98: #f7f9ff;
  --qaroni-primary99: #fcfcff;
  --qaroni-primary100: #ffffff;

  --qaroni-accent: #ed985f;
  --qaroni-accent0: #000000;
  --qaroni-accent10: #311300;
  --qaroni-accent20: #512400;
  --qaroni-accent25: #622c00;
  --qaroni-accent30: #733500;
  --qaroni-accent35: #853f00;
  --qaroni-accent40: #954907;
  --qaroni-accent50: #b46122;
  --qaroni-accent60: #d47a39;
  --qaroni-accent70: #f49350;
  --qaroni-accent80: #ffb688;
  --qaroni-accent90: #ffdbc7;
  --qaroni-accent95: #ffede4;
  --qaroni-accent98: #fff8f5;
  --qaroni-accent99: #fffbff;
  --qaroni-accent100: #ffffff;

  --qaroni-warn: #f88282;
  --qaroni-warn0: #000000;
  --qaroni-warn10: #410007;
  --qaroni-warn20: #620f17;
  --qaroni-warn25: #711b21;
  --qaroni-warn30: #80272b;
  --qaroni-warn35: #903236;
  --qaroni-warn40: #a03e41;
  --qaroni-warn50: #bf5657;
  --qaroni-warn60: #df6f6f;
  --qaroni-warn70: #ff8888;
  --qaroni-warn80: #ffb3b1;
  --qaroni-warn90: #ffdad8;
  --qaroni-warn95: #ffedeb;
  --qaroni-warn98: #fff8f7;
  --qaroni-warn99: #fffbff;
  --qaroni-warn100: #ffffff;

  --qaroni-secondary: #66a257;
  --qaroni-secondary0: #000000;
  --qaroni-secondary10: #002200;
  --qaroni-secondary20: #013a00;
  --qaroni-secondary25: #064603;
  --qaroni-secondary30: #16520e;
  --qaroni-secondary35: #235e1a;
  --qaroni-secondary40: #306b25;
  --qaroni-secondary50: #49853c;
  --qaroni-secondary60: #629f53;
  --qaroni-secondary70: #7bbb6b;
  --qaroni-secondary80: #96d784;
  --qaroni-secondary90: #b1f49d;
  --qaroni-secondary95: #cbffb8;
  --qaroni-secondary98: #edffe1;
  --qaroni-secondary99: #f7ffee;
  --qaroni-secondary100: #ffffff;

  --qaroni-tertiary: #05998c;
  --qaroni-tertiary0: #000000;
  --qaroni-tertiary10: #00201d;
  --qaroni-tertiary20: #003732;
  --qaroni-tertiary25: #00443d;
  --qaroni-tertiary30: #005049;
  --qaroni-tertiary35: #005d55;
  --qaroni-tertiary40: #006a61;
  --qaroni-tertiary50: #00867a;
  --qaroni-tertiary60: #00a295;
  --qaroni-tertiary70: #2bbfaf;
  --qaroni-tertiary80: #52dbcb;
  --qaroni-tertiary90: #73f8e7;
  --qaroni-tertiary95: #b3fff3;
  --qaroni-tertiary98: #e4fff9;
  --qaroni-tertiary99: #f2fffb;
  --qaroni-tertiary100: #ffffff;

  --qaroni-neutral0: #000000;
  --qaroni-neutral10: #1a1c1e;
  --qaroni-neutral20: #2e3133;
  --qaroni-neutral25: #3a3c3e;
  --qaroni-neutral30: #454749;
  --qaroni-neutral35: #515255;
  --qaroni-neutral40: #5d5e61;
  --qaroni-neutral50: #76777a;
  --qaroni-neutral60: #8f9193;
  --qaroni-neutral70: #aaabae;
  --qaroni-neutral80: #c6c6c9;
  --qaroni-neutral90: #e2e2e5;
  --qaroni-neutral95: #f0f0f3;
  --qaroni-neutral98: #f9f9fc;
  --qaroni-neutral99: #fcfcff;
  --qaroni-neutral100: #ffffff;

  --qaroni-error: #f88282;
  --qaroni-error0: #000000;
  --qaroni-error10: #410007;
  --qaroni-error20: #620f17;
  --qaroni-error25: #711b21;
  --qaroni-error30: #80272b;
  --qaroni-error35: #903236;
  --qaroni-error40: #a03e41;
  --qaroni-error50: #bf5657;
  --qaroni-error60: #df6f6f;
  --qaroni-error70: #ff8888;
  --qaroni-error80: #ffb3b1;
  --qaroni-error90: #ffdad8;
  --qaroni-error95: #ffedeb;
  --qaroni-error98: #fff8f7;
  --qaroni-error99: #fffbff;
  --qaroni-error100: #ffffff;

  --qaroni-background: #ffffff;
  --qaroni-on-background: #222222;
  --qaroni-background-border: #e2e2e5;
  --qaroni-background-variant: #fafafa;
  --qaroni-on-background-variant: #aaabae;

  --qaroni-surface: #f9f9fc;
  --qaroni-on-surface: #222222;
  --qaroni-surface-border: #e2e2e5;
  --qaroni-surface-variant: #e2e2e5;
  --qaroni-on-surface-variant: #2e3133;

  --qaroni-success: #66a257;

  --qaroni-danger: #a03e41;
  --qaroni-on-danger: #ffedeb;
  --qaroni-danger-light: #f88282;

  --qaroni-cafe: #a7754d;

  --qaroni-stripe: #625afa;
}
