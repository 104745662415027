.qaroni-text-title {
  color: var(--qaroni-background);
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.qaroni-common-text {
  font-size: 16px;
}

.qaroni-title {
  color: var(--qaroni-neutral20);
  font-size: 28px !important;
  font-weight: 500;
}

.qaroni-title-dashboard-container {
  padding-top: 8px;
  padding-bottom: 8px;

  .qaroni-title-dashboard {
    color: var(--qaroni-neutral70);
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    line-height: 36px;
    text-transform: uppercase;
  }
}

.qaroni-subtitle {
  color: var(--qaroni-on-background);
  font-size: 18px !important;
  font-weight: bold;
  text-transform: uppercase;
}

.qaroni-detail-title {
  color: var(--qaroni-on-background);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
}

.qaroni-message-text {
  color: var(--qaroni-black);
  font-size: 20px;
}

.qaroni-text-time {
  font-weight: 300;
  font-size: 12px;
}
