%qaroni-close-icon-base {
  position: absolute;
  top: 20px;
  padding: 5px;
  line-height: 10px;
  min-width: auto;
}

%qaroni-mat-dialog-title-base {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  color: var(--qaroni-neutral30);
}

%qaroni-mat-dialog-message-base {
  font-size: 16px;
  text-align: center;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  margin-bottom: 20px;
}

%qaroni-mat-dialog-container-base {
  padding-top: 42px;
  padding-bottom: 42px;
  justify-content: center;
}

.qaroni-style-close-dialog {
  position: relative !important;

  .qaroni-close-icon.mat-mdc-button {
    @extend %qaroni-close-icon-base;
    right: 20px;
    margin-left: auto;
    margin-right: auto;
    mat-icon {
      margin: 0px;
    }
  }

  .mat-mdc-dialog-container {
    .mat-mdc-dialog-title {
      @extend %qaroni-mat-dialog-title-base;
    }

    .mat-mdc-dialog-surface {
      @extend %qaroni-mat-dialog-container-base;
    }

    .mat-mdc-dialog-content {
      padding-bottom: 8px;
    }

    border-radius: 10px;
  }

  .mat-mdc-dialog-content.mat-dialog-message {
    @extend %qaroni-mat-dialog-message-base;
    padding-bottom: 0px;
  }

  @media screen and (max-width: 768px) {
    .qaroni-close-icon.mat-mdc-button {
      top: 12px;
      right: 12px;
    }

    .mat-mdc-dialog-container {
      .mat-mdc-dialog-surface {
        padding-top: 36px;
        padding-bottom: 32px;
      }
    }
  }
}

.qaroni-style-full-close-dialog {
  position: absolute !important;
  right: 0;
  height: 100vh;
  animation: qaroni-dialog-enter 500ms ease;

  .qaroni-close-icon.mat-mdc-button {
    @extend %qaroni-close-icon-base;
    left: 20px;
    margin-left: auto;
    margin-right: auto;
  }

  .mat-mdc-dialog-title {
    @extend %qaroni-mat-dialog-title-base;
  }

  .mat-dialog-message {
    @extend %qaroni-mat-dialog-message-base;
  }

  .mat-mdc-dialog-container {
    .mat-mdc-dialog-surface {
      @extend %qaroni-mat-dialog-container-base;
      display: flex;
      align-items: center;
    }

    border-radius: 10px 0 0 10px;
  }
}

.mat-mdc-dialog-container .mat-mdc-dialog-title + .mat-mdc-dialog-content {
  padding-top: 8px !important;
}

@keyframes qaroni-dialog-enter {
  from {
    transform: translate(100%, 0);
  }

  to {
    transform: 0%;
  }
}

.cdk-overlay-dark-backdrop {
  background-color: transparent;
}
