%qaroni-webkit-scrollbar {
  width: 7px;
  height: 12.5px;
}

%qaroni-webkit-scrollbar-track {
  background: transparent;
}

%qaroni-webkit-scrollbar-thumb {
  background-color: var(--qaroni-primary);
  border-radius: 1rem;
  border: 0.5px solid var(--qaroni-background);
}

body,
.content,
.mat-drawer-inner-container {
  &::-webkit-scrollbar {
    @extend %qaroni-webkit-scrollbar;
  }

  &::-webkit-scrollbar-track {
    @extend %qaroni-webkit-scrollbar-track;
  }

  &::-webkit-scrollbar-thumb {
    @extend %qaroni-webkit-scrollbar-thumb;
  }
}
