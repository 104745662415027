$colors: (
  'primary': var(--qaroni-primary),
  'primary0': var(--qaroni-primary0),
  'primary10': var(--qaroni-primary10),
  'primary20': var(--qaroni-primary20),
  'primary25': var(--qaroni-primary25),
  'primary30': var(--qaroni-primary30),
  'primary35': var(--qaroni-primary35),
  'primary40': var(--qaroni-primary40),
  'primary50': var(--qaroni-primary50),
  'primary60': var(--qaroni-primary60),
  'primary70': var(--qaroni-primary70),
  'primary80': var(--qaroni-primary80),
  'primary90': var(--qaroni-primary90),
  'primary95': var(--qaroni-primary95),
  'primary98': var(--qaroni-primary98),
  'primary99': var(--qaroni-primary99),
  'primary100': var(--qaroni-primary100),
  // accent
  'accent': var(--qaroni-accent),
  'accent0': var(--qaroni-accent0),
  'accent10': var(--qaroni-accent10),
  'accent20': var(--qaroni-accent20),
  'accent25': var(--qaroni-accent25),
  'accent30': var(--qaroni-accent30),
  'accent35': var(--qaroni-accent35),
  'accent40': var(--qaroni-accent40),
  'accent50': var(--qaroni-accent50),
  'accent60': var(--qaroni-accent60),
  'accent70': var(--qaroni-accent70),
  'accent80': var(--qaroni-accent80),
  'accent90': var(--qaroni-accent90),
  'accent95': var(--qaroni-accent95),
  'accent98': var(--qaroni-accent98),
  'accent99': var(--qaroni-accent99),
  'accent100': var(--qaroni-accent100),
  // warn
  'warn': var(--qaroni-warn),
  'warn0': var(--qaroni-warn0),
  'warn10': var(--qaroni-warn10),
  'warn20': var(--qaroni-warn20),
  'warn25': var(--qaroni-warn25),
  'warn30': var(--qaroni-warn30),
  'warn35': var(--qaroni-warn35),
  'warn40': var(--qaroni-warn40),
  'warn50': var(--qaroni-warn50),
  'warn60': var(--qaroni-warn60),
  'warn70': var(--qaroni-warn70),
  'warn80': var(--qaroni-warn80),
  'warn90': var(--qaroni-warn90),
  'warn95': var(--qaroni-warn95),
  'warn98': var(--qaroni-warn98),
  'warn99': var(--qaroni-warn99),
  'warn100': var(--qaroni-warn100),
  // secondary
  'secondary': var(--qaroni-secondary),
  'secondary0': var(--qaroni-secondary0),
  'secondary10': var(--qaroni-secondary10),
  'secondary20': var(--qaroni-secondary20),
  'secondary25': var(--qaroni-secondary25),
  'secondary30': var(--qaroni-secondary30),
  'secondary35': var(--qaroni-secondary35),
  'secondary40': var(--qaroni-secondary40),
  'secondary50': var(--qaroni-secondary50),
  'secondary60': var(--qaroni-secondary60),
  'secondary70': var(--qaroni-secondary70),
  'secondary80': var(--qaroni-secondary80),
  'secondary90': var(--qaroni-secondary90),
  'secondary95': var(--qaroni-secondary95),
  'secondary98': var(--qaroni-secondary98),
  'secondary99': var(--qaroni-secondary99),
  'secondary100': var(--qaroni-secondary100),
  // tertiary
  'tertiary': var(--qaroni-tertiary),
  'tertiary0': var(--qaroni-tertiary0),
  'tertiary10': var(--qaroni-tertiary10),
  'tertiary20': var(--qaroni-tertiary20),
  'tertiary25': var(--qaroni-tertiary25),
  'tertiary30': var(--qaroni-tertiary30),
  'tertiary35': var(--qaroni-tertiary35),
  'tertiary40': var(--qaroni-tertiary40),
  'tertiary50': var(--qaroni-tertiary50),
  'tertiary60': var(--qaroni-tertiary60),
  'tertiary70': var(--qaroni-tertiary70),
  'tertiary80': var(--qaroni-tertiary80),
  'tertiary90': var(--qaroni-tertiary90),
  'tertiary95': var(--qaroni-tertiary95),
  'tertiary98': var(--qaroni-tertiary98),
  'tertiary99': var(--qaroni-tertiary99),
  'tertiary100': var(--qaroni-tertiary100),
  // neutral
  'neutral0': var(--qaroni-neutral0),
  'neutral10': var(--qaroni-neutral10),
  'neutral20': var(--qaroni-neutral20),
  'neutral25': var(--qaroni-neutral25),
  'neutral30': var(--qaroni-neutral30),
  'neutral35': var(--qaroni-neutral35),
  'neutral40': var(--qaroni-neutral40),
  'neutral50': var(--qaroni-neutral50),
  'neutral60': var(--qaroni-neutral60),
  'neutral70': var(--qaroni-neutral70),
  'neutral80': var(--qaroni-neutral80),
  'neutral90': var(--qaroni-neutral90),
  'neutral95': var(--qaroni-neutral95),
  'neutral98': var(--qaroni-neutral98),
  'neutral99': var(--qaroni-neutral99),
  'neutral100': var(--qaroni-neutral100),
  // error
  'error': var(--qaroni-error),
  'error0': var(--qaroni-error0),
  'error10': var(--qaroni-error10),
  'error20': var(--qaroni-error20),
  'error25': var(--qaroni-error25),
  'error30': var(--qaroni-error30),
  'error35': var(--qaroni-error35),
  'error40': var(--qaroni-error40),
  'error50': var(--qaroni-error50),
  'error60': var(--qaroni-error60),
  'error70': var(--qaroni-error70),
  'error80': var(--qaroni-error80),
  'error90': var(--qaroni-error90),
  'error95': var(--qaroni-error95),
  'error98': var(--qaroni-error98),
  'error99': var(--qaroni-error99),
  'error100': var(--qaroni-error100),
  // background
  'background': var(--qaroni-background),
  'on-background': var(--qaroni-on-background),
  'background-border': var(--qaroni-background-border),
  'background-variant': var(--qaroni-background-variant),
  'on-background-variant': var(--qaroni-on-background-variant),
  // surface
  'surface': var(--qaroni-surface),
  'on-surface': var(--qaroni-on-surface),
  'surface-border': var(--qaroni-surface-border),
  'surface-variant': var(--qaroni-surface-variant),
  'on-surface-variant': var(--qaroni-on-surface-variant),
  // success
  'success': var(--qaroni-success),
  // danger
  'danger': var(--qaroni-danger),
  'on-danger': var(--qaroni-on-danger),
  'danger-light': var(--qaroni-danger-light),
  //
  'cafe': var(--qaroni-cafe),
  // brands
  'stripe': var(--qaroni-stripe),
);

@each $name, $color in $colors {
  .qaroni-bg-#{$name} {
    background-color: $color !important;
  }

  .qaroni-text-#{$name} {
    color: $color !important;
  }
}
